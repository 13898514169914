<template>
  <v-container fluid class="pa-0 ma-0 bg fill-height" fill-height >
    <Header />
    <v-container align="center" justify="center" class="mt-6 rounded-t-lg bodq" id="cajita">
      <v-card>
        <v-card-title>Reporte de autodiagnóstico<v-spacer></v-spacer> 🤓</v-card-title>
        <v-card-subtitle>Descargar.</v-card-subtitle>
        <v-card-text>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore veritatis magni eius totam neque possimus labore eligendi. Est laboriosam quae natus voluptatum quo dolorum distinctio, illo, et alias animi reiciendis.</p>


          <pre style="white-space: pre-wrap;">
            {{dataReporte.perfil}}
          </pre>
          <v-img src="@/assets/matriz.png"></v-img>
          <pre style="white-space: pre-wrap;">
            {{ dataReporte }}
          </pre>



          <v-row>
            <v-col class="text-right">
              <v-btn x-large block elevation="0" color="primary" >Imprimir reporte <v-icon>mdi-arrow-right-thick</v-icon></v-btn>
            </v-col>
          </v-row>



        </v-card-text>
        <v-card-actions class="justify-center">
          <p class="text--secondary text-center"><small>Durante el autodiagnóstico los datos son guardados en la memoria local de tu navegador. Nosotros no guardamos, enviamos o recopilamos tus datos no obstante si lo deseas al final puedes compartir tus resultados anónimamente o con un correo para seguimiento por nuestra parte.</small></p>
        </v-card-actions>
      </v-card>
      
    </v-container>
    <Footer />
  </v-container>
</template>

<script>
// import { mapState } from 'vuex'
import Header from '@/layout/Header'
import Footer from '@/layout/Footer'


export default {
  name: 'Reporte',
  data () {
    return {
      dataReporte: {}
    }
  },
  created() {
    this.construReporte()
  },
  methods: {
    construReporte() {
      this.dataReporte.perfil = JSON.parse(localStorage.getItem('my-quiz-perfil'))
      this.dataReporte.habitos = JSON.parse(localStorage.getItem('my-quiz-habitos'))
      this.dataReporte.agresiones = JSON.parse(localStorage.getItem('my-quiz-agresiones'))
    }
  },
  components: {
    Header, Footer
  },
}
</script>
<style scoped>
.bodq {
  background-color: rgba(255, 255, 255, .5);
}
.bg {
  content: "";
  background-image: url(~@/assets/head_2.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 308px;
}
.bg::before {
  content: "";
  background-color: rgba(255,255,255, 0.1);
}
</style>
