<template>
  <v-app-bar app flat dense elevate-on-scroll>
    <v-container class="pa-0 fill-height">
      <v-img dark class="mx-2" src="@/assets/logo.svg" max-height="40" max-width="40" contain gradient=""></v-img>

      <v-toolbar-title class="font-weight-black pl-2">
        <!-- <img src="@/assets/logo.svg" class="logo" alt="Mi autodiagnóstico digital"> -->
        <a href="/">Mi autodiagnóstico digital</a></v-toolbar-title>
      <!-- <v-toolbar-title class="font-weight-black pl-2">{{ content.titulo }}</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <!-- <v-checkbox class="pt-6" v-model="$vuetify.theme.dark" color="light" off-icon="mdi-theme-light-dark" on-icon="mdi-theme-light-dark"></v-checkbox> -->
      <v-btn icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Header',
  data: () => ({
   
  }),
  computed: {
      ...mapState(['isLoading'])
    }
}
</script>

<style scoped>
.v-toolbar__title a {
  color: rgba(94, 86, 105, 87%);
  text-decoration: none;
}
.v-toolbar__title a:hover {
  color: rgba(94, 86, 105, 100%);
}
.logo {
  max-height: 100%;
  width: auto;
  filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);

}
/* .v-image__image v-image__image--contain */

</style>
